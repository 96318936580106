import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './App.css';
import { useInterval } from './hooks';
import DefaultView from './views/DefaultView';
import EnvironmentBar from './components/EnvironmentBar';
import { fetchDeliverySite } from './library/BffFuncs.js';
import { renderTimespanToAgoPhrase } from './library/DateTimeFuncs.js';

// all views that we support
const VIEWS = {
  default: "default"
};

function App() {
  // define state and defaults
  const [dateTimeDependency, setDateTimeDependency] = useState(new Date());
  const [dateTimeLastLoaded, setDateTimeLastLoaded] = useState(new Date());
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [theDeliverySite, setTheDeliverySite] = useState(undefined);

  // use the location code if it is part of the route params
  let { locationCode } = useParams();

  // fetch parameters from the URI
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  // if we don't have a uniqueRef as a route param then try get it from the query params
  if (!locationCode) {
    locationCode = urlParams.get("locationCode");
  }

  // determine the selected view from the URI
  const viewParam = urlParams.get("view");

  let renderView;
  switch (viewParam === undefined || viewParam === null ? VIEWS.default : viewParam.toLowerCase()) {
    case VIEWS.dash:
    default:
      renderView = <DefaultView locationCode={locationCode} theDeliverySite={theDeliverySite} loadingMessage={loadingMessage} isFirstLoad={isFirstLoad} />
      break;
  }

  // define class loader function (running in intervals)
  useInterval(() => {
    setDateTimeDependency(new Date())
  }, 1000); // polls for updates every 1 second

  useEffect(() => {
    async function fetchTheClass() {
      // exit if something is loading
      if (isLoading) {
        return;
      }

      // exit if the class has been fetched just recently, so we won't fetch right now
      let timeSpan = new Date() - dateTimeLastLoaded;
      if (!isFirstLoad && timeSpan < 59.5 * 1000) { // nearly 1 minute
        setLoadingMessage(renderTimespanToAgoPhrase(dateTimeLastLoaded, "Info refreshed "));
        return;
      }

      // fetch the delivery site
      setIsLoading(true);
      setLoadingMessage("Refreshing ...");
      var loadedDeliverySite = await fetchDeliverySite(locationCode);
      setIsFirstLoad(false);
      setTheDeliverySite(loadedDeliverySite);
      setDateTimeLastLoaded(new Date()); // remember when the class was loaded last (for status rendering)

      // remove loading message
      setIsLoading(false);
    }
    fetchTheClass();
  }, [dateTimeDependency, dateTimeLastLoaded, isFirstLoad, isLoading, locationCode]);

  return (
    <div className="App">
      <EnvironmentBar />
      {renderView}
    </div>
  );
}

export default App;
