import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { MsalProvider, MsalAuthenticationTemplate } from '@azure/msal-react';
import { PublicClientApplication, InteractionType } from '@azure/msal-browser';

// Azure Application (client) ID
const azureApplicationId = process.env.REACT_APP_AZURE_APPLICATION_ID || 'BUGGER:REACT_APP_AZURE_APPLICATION_ID';
// Azure Directory (tenant) ID
const azureTenantUrl = process.env.REACT_APP_AZURE_DIRECTORY_URL || 'BUGGER:REACT_APP_AZURE_DIRECTORY_URL';

// MSAL configuration
const msalConfiguration = {
  auth: {
    clientId: azureApplicationId,
    authority: azureTenantUrl 
  }
};

// First need to initialize an instance of PublicClientApplication then pass this to MsalProvider as a prop.
const msalInstance = new PublicClientApplication(msalConfiguration);

function ErrorComponent({ error }) {
  return <p>An Error Occurred: {error}</p>;
}
function LoadingComponent() {
  return <p>Authentication in progress...</p>;
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        errorComponent={ErrorComponent}
        loadingComponent={LoadingComponent}>
      </MsalAuthenticationTemplate>  
      <Router>
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/:locationCode" element={<App />} />
        </Routes>
      </Router>
    </MsalProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
