// fetch the delivery site
export async function fetchDeliverySite(
  locationCode // the reference that identified this delivery site
) {
  // call the API and get the delivery site
  let result = undefined; // default result if we can't fetch anything

  // exit early if someone hasn't provided a location code
  if (!locationCode || locationCode.length === 0) {
    return result;
  }

  // prepare for loading the delivery site
  const apiEndpoint = process.env.REACT_APP_API_BFF_BASE || 'BUGGER:REACT_APP_API_BFF_BASE';
  let apiUri = apiEndpoint + '/deliverySites/' + locationCode;
  console.log('Calling ' + apiUri + ' ...');
  let response = await fetch(apiUri, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    mode: 'cors' // TODO SO: ensure this is going to be a CORS call at some point
  }).then(
    response => {
      // if everything is okay, bypass the response code handling
      if (response.ok)
        return response;

      // now handle error edge cases
      switch (response.status) {
        case 404: // 404 means no delivery site was found
          console.log("Delivery site not found.");
          return undefined;
        case 409: // 409 means there is an issue with the data integrity in the API
          throw new Error('DATA INTEGRITY ERROR: ' + response.status + ': ' + response.statusText);
        default: // Other errors are not expected but UI should notify user
          throw new Error('UNEXPECTED ERROR: ' + response.status + ': ' + response.statusText);
      }
    }
    // it's important to handle errors here instead of a catch() block so that we don't swallow exceptions from actual bugs in components
  ).catch(err => {
    // TODO:MC - stop swallowing exceptions
    console.log(err.message);
  });

  if (!!response && response.ok) {
    const data = await response.json();
    result = {
      activityState: data.activityState === null ? undefined : data.activityState,
      addressLine1: data.addressLine1 === null ? undefined : data.addressLine1,
      addressLine2: data.addressLine2 === null ? undefined : data.addressLine2,
      addressLine3: data.addressLine3 === null ? undefined : data.addressLine3,
      isActivityStateManagedByCatalog: data.isActivityStateManagedByCatalog,
      isAddressManagedByCatalog: data.isAddressManagedByCatalog,
      locationCode: data.locationCode,
      physicalAddressValidationOutcome: data.physicalAddressValidationOutcome
    };
    console.log("Delivery site loaded.");
  }

  // Done.
  return result;
}
