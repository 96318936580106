import React from 'react';
import './DeliverySiteBodyElement.css';
import DeliverySiteBodyElementContent from './DeliverySiteBodyElementContent';

// render the delivery site body element
const DeliverySiteBodyElement = (props) => {
  const icon = props.icon;
  const iconAlt = props.iconAlt === undefined ? "" : props.iconAlt;
  const isVisible = props.visible === undefined ? true : props.visible;
  const labelClassName = props.className === undefined ? "DeliverySiteBodyElement-label" : "DeliverySiteBodyElement-label " + props.className;
  const text1 = props.text1 === undefined ? "" : props.text1;
  const text2 = props.text2 === undefined ? "" : props.text2;
  const text3 = props.text3 === undefined ? "" : props.text3;
  const title = props.title;
  const secondSubIcon = props.secondSubIcon;
  const secondSubIconAlt = props.secondSubIconAlt === undefined ? "" : props.secondSubIconAlt;
  const secondSubText = props.secondSubText === undefined ? "" : props.secondSubText;
  const subIcon = props.subIcon;
  const subIconAlt = props.subIconAlt === undefined ? "" : props.subIconAlt;
  const subText = props.subText === undefined ? "" : props.subText;
  const linkText = !!props.linkText ? props.linkText : "";
  const linkAddress = !!props.linkAddress ? props.linkAddress : "";

  // exit if the element is invisible
  if (!isVisible)
    return (<></>);

  // render all the content we need to subject to the data that we have been given
  const textFragment = text1 !== "" || text2 !== "" || text3 !== ""
    ?
    <>
      <DeliverySiteBodyElementContent text={text1} visible={text1 !== ""} icon={icon} iconAlt={iconAlt} />
      <DeliverySiteBodyElementContent text={text2} visible={text2 !== ""} />
      <DeliverySiteBodyElementContent text={text3} visible={text3 !== ""} />
    </>
    : <></>
  const subTextFragment = subText !== ""
    ? <DeliverySiteBodyElementContent text={subText} icon={subIcon} iconAlt={subIconAlt} classStyle="subText" />
    : <></>
  const secondSubTextFragment = secondSubText !== ""
    ? <DeliverySiteBodyElementContent text={secondSubText} icon={secondSubIcon} iconAlt={secondSubIconAlt} classStyle="subText" />
    : <></>
  const linkButtonFragment = linkText !== ""
    ? <DeliverySiteBodyElementContent text="" linkText={linkText} linkAddress={linkAddress} />
    : <></>

  // render the element
  return (<>
    <div className="DeliverySiteBodyElement">
      <div className={labelClassName}>{title}</div>
      <div className="DeliverySiteBodyElement-content">
        {textFragment}
        {subTextFragment}
        {secondSubTextFragment}
        {linkButtonFragment}
      </div>
    </div>
  </>);
}

export default DeliverySiteBodyElement;