import React from 'react';
import './DeliverySiteHeader.css';

const DeliverySiteHeader = (props) => {
  const isFirstLoad = props.isFirstLoad;
  const loadingMessage = isFirstLoad ? "" : props.loadingMessage;
  const locationCode = props.locationCode;

  // render
  return (<>
    <div className="DeliverySiteHeader">
      <div className="App-wrapper DeliverySiteHeader-wrapper">
        <div className="DeliverySiteHeader-title">Delivery Site {locationCode}</div>
        <div className="DeliverySiteHeader-loading-message">{loadingMessage}</div>
      </div>
    </div>
  </>);
}

export default DeliverySiteHeader;