import React from 'react';
import './DeliverySiteBodyElementContent.css';
import { renderDateAndTimeToRecentPhrase } from '../library/DateTimeFuncs.js';
import { useMsal } from '@azure/msal-react';

// render some content of the delivery site body element
const DeliverySiteBodyElementContent = (props) => {
  const dateAndTime = props.dateAndTime === undefined ? undefined : props.dateAndTime;
  const dateAndTimePrefix = props.dateAndTimePrefix === undefined ? "" : props.dateAndTimePrefix;
  const icon = props.icon;
  const hasDateAndTime = dateAndTime !== undefined && Object.prototype.toString.call(dateAndTime) === '[object Date]' && dateAndTime.getFullYear() >= 2022;
  const hasIcon = icon !== undefined;
  const iconAlt = props.iconAlt === undefined ? "" : props.iconAlt;
  const isVisible = props.visible === undefined ? true : props.visible;
  const classStyle = props.classStyle === undefined ? "" : props.classStyle;
  const text = props.text;
  const textColor = props.textColor === undefined ? undefined : props.textColor;
  const linkText = !!props.linkText ? props.linkText : "";
  const hasLinkText = linkText !== "";
  const linkAddress = !!props.linkAddress ? props.linkAddress : "";

  const {instance} = useMsal();
  let allAccounts = instance.getAllAccounts()
  let account = null;
  let roles = [];

  if (allAccounts.length > 0) {
    account = allAccounts[0];
    roles = (account && account.idTokenClaims && account.idTokenClaims.roles) || [];
  }

  // exit if the element is invisible
  if (!isVisible)
    return (<></>);

  // render icon
  const iconContent = hasIcon
    ? <><div className="DeliverySiteBodyElementContent-icon"><img src={icon} width="13" height="13" alt={iconAlt} /></div></>
    : <></>;

  // render date and time
  const dateAndTimeText = hasDateAndTime
    ? dateAndTimePrefix + renderDateAndTimeToRecentPhrase(dateAndTime, dateAndTimePrefix.length === 0)
    : "";

  // render text
  const fullText = text + dateAndTimeText;
  const textFragment = textColor === undefined
    ? fullText
    : <span className={textColor}>{fullText}</span>;
  const textContent = !hasLinkText
    ? <><div className="DeliverySiteBodyElementContent-text">{textFragment}</div></>
    : <></>;

  // render link
  const linkContent = hasLinkText
    ? <a className="button" href={linkAddress}>{linkText}</a>
    : <></>;

  // render the element
  return (<>
    <div className={"DeliverySiteBodyElementContent" + (classStyle.length ? "-" + classStyle : "")}>
      {iconContent}
      {textContent}
      {
        roles.includes('User.Edit')
          ? linkContent
          : <></>
      }
    </div>
  </>);
}

export default DeliverySiteBodyElementContent;